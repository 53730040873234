import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import logo from "../images/wb_logo_v2_v.svg"
import { useState } from 'react';

const Header = ({ siteTitle }) => {
  const [hamBtn, setHamBtn] = useState("hamburger");
  const [menuM, setMenuM] = useState("header_navbox_m");

  return (
  <header
    className="header_wrapper"
    // style={{
    //   // background: `#131a18`,
    //   background: `#000`,
    //   boxSizing: "border-box",
    //   color: "#fff",
    // }}
  >
    <Row style={{ boxSizing: "border-box", margin: 0, height: "100%" }}>
      <Col
        sm="6"
        xs="9"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {/* <h1 style={{ margin: 0, padding: 10 }}> */}
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img src={logo} alt="site_logo" style={{ height: 40 }}></img>
        </Link>
        {/* </h1> */}
      </Col>
      <Col sm="6" className="menu-pc" style={{ height: "100%" }}>
        <Row className="header_navbox">
          <Col sm={3} className="header_navitem">
            <Link className="header-link" to={`/work/`}>
              Art
            </Link>
          </Col>
          <Col sm={3} className="header_navitem">
            <Link className="header-link" to={`/pproject/`}>
              Spiritual
            </Link>
          </Col>
          <Col sm={3} className="header_navitem">
            <Link className="header-link" to={`/about/`}>
              About
            </Link>
          </Col>
          <Col sm={3} className="header_navitem">
            <Link className="header-link" to={`/#contact`}>
              Contact
            </Link>
          </Col>
        </Row>
      </Col>
      <Col xs="3" sm={{size: 2, offset: 4}} >
      <div class={hamBtn} onClick={()=>{
        if(hamBtn !== "hamburger") {
          setHamBtn("hamburger")
          setMenuM("header_navbox_m")
        } else {
          setHamBtn(hamBtn.concat(" is-active"))
          setMenuM(menuM.concat(" menu-active"))
        }
      }}>
        <div class="hamburger__container">
          <div class="hamburger__inner"></div>
          <div class="hamburger__hidden"></div>
        </div>
      </div></Col>
    </Row>
    <Row className={menuM}>
          {/* <Col sm={3} className="header_navitem"> */}
            <Link className="header-link-m" to={`/work/`}>
              Art Work
            </Link>
          {/* </Col> */}
          {/* <Col sm={3} className="header_navitem"> */}
            <Link className="header-link-m" to={`/pproject/`}>
              Spiritual
            </Link>
          {/* </Col> */}
          {/* <Col sm={3} className="header_navitem"> */}
            <Link className="header-link-m" to={`/about/`}>
              About
            </Link>
          {/* </Col> */}
          {/* <Col sm={3} className="header_navitem"> */}
            <Link className="header-link-m" to={`/#contact`}>
              Contact
            </Link>
          {/* </Col> */}
        </Row>
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
